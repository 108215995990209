import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowLeft, Send,ChevronRight } from 'lucide-react';
import axios from 'axios';
import './AppCSS/App.css';
import MonetizationFlow from './payment';
import TermsAndConditions from './TermsAndConditions';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap" rel="stylesheet"></link>

const characters = [
  { id: 1, name: 'Tony Stark', image: '/images/tony-stark.png', description: 'Genius billionaire playboy philanthropist' },
  { id: 2, name: 'Gojo Satoru', image: '/images/gojo-satoru.png', description: 'I alone am the honored one' },
  { id: 3, name: 'Naruto Uzumaki', image: '/images/naruto-uzumaki.png', description: "Believe it! I'm gonna be Hokage!" },
  { id: 4, name: 'Elon Musk', image: '/images/elon-musk.png', description: 'Nuke Mars!' },
  { id: 5, name: 'Deadpool', image: '/images/deadpool.png', description: 'Merc with a Mouth' },
  { id: 6, name: 'CarryMinati', image: '/images/carryminati.png', description: 'To Chaliye Shuru Karte Hai!' },
  { id: 7, name: 'Shin Chan', image: '/images/shin-chan.png', description: 'Mai Itna Bhi Khas Nhi!' },
  { id: 8, name: 'Triggered Insaan', image: '/images/triggered-insaan.png', description: 'Yeah its me!' },
  { id: 9, name: 'Ben 10', image: '/images/ben-10.png', description: "It's hero time!" },
  { id: 10, name: 'Goku', image: '/images/goku.png', description: 'Kamehameha!' },
  { id: 11, name: 'Doraemon', image: '/images/doraemon.png', description: 'I have got a gadget for that!' },
  { id: 12, name: 'Monkey D. Luffy', image: '/images/luffy.png', description: "I'm gonna be King of the Pirates!" },
  { id: 13, name: 'Lelouch vi Britannia', image: '/images/lelouch.png', description: 'Obey me Subjects!' },
  { id: 14, name: 'Light Yagami', image: '/images/light.png', description: 'I will create a new world.' },
  { id: 15, name: 'Kakashi Hatake', image: '/images/kakashi.png', description: "Sorry I'm late, I got lost on the path of life" },
  { id: 16, name: 'Zoro', image: '/images/zoro.png', description:  "I'm going to be the world's greatest swordsman!" },
  { id: 17, name: 'Itachi Uchiha', image: '/images/itachi.png', description: "You don't have enough hatred."},
  { id: 18, name: 'Tanjiro Kamado', image: '/images/tanjiro.png', description: "I smell the scent of a demon!" },
  { id: 19, name: 'Vegeta', image: '/images/vegeta.png', description: "I am the Prince of all Saiyans!" },
  { id: 20, name: 'Thor', image: '/images/thor.png', description: "For Asgard!" },
  { id: 21, name: 'Madara Uchiha', image: '/images/madara.png', description: 'Wake Up To Reality' },
  { id: 22, name: 'Ninja Hattori', image: '/images/hattori.png', description: 'Ding Ding!' },


];

const stories = [
  { id: 1, title: 'Alone in the Haunted Mansion', image: '/images/haunted-mansion.jpg' },
  { id: 2, title: 'Alone on a Distant Planet', image: '/images/distant-planet.jpg' },
  { id: 3, title: 'On Mission with Naruto', image: '/images/naruto-mission.jpg' },
  { id: 4, title: 'Time Travel Paradox', image: '/images/time-travel.jpg' },
  { id: 5, title: 'Doctor Doom Destroying the Multiverse', image: '/images/multiverse.jpg' },
  { id: 6, title: 'You are A Pokemon Master', image: '/images/pokemon.jpg' },
  { id: 7, title: 'AI is Ruling the World', image: '/images/ai-rebellion.jpg' },
  { id: 8, title: 'You Are The Richest Person in The World', image: '/images/richest.png' },
  { id: 9, title: 'Solving the Murder Mystery', image: '/images/murder-mystery.png' },
  { id: 10, title: 'Alone in the Jungle', image: '/images/jungle.jpg' },
  { id: 11, title: 'Aliens vs Humans', image: '/images/avh.jpg' },
  { id: 12, title: 'You are Nobita in Doraemon Universe', image: '/images/n_in_d_u.png' },
  { id: 13, title: 'Training with Goku', image: '/images/gokutraining.png' },
  { id: 14, title: 'You Are Ironman Fighting Thanos', image: '/images/I_fight_thanos.png' },
  { id: 15, title: 'Mind Battle With Smartest Person', image: '/images/mind_battle.jpg' },
  { id: 16, title: 'You are a Akatsuki Member', image: '/images/akatsuki_member.png' },
  { id: 17, title: 'You are the Last Person on Earth', image: '/images/lastperson.jpg' },
  { id: 18, title: 'Space Battle', image: '/images/spacebattle.jpg' },
  { id: 19, title: 'Goku vs Vegeta', image: '/images/gokuvvegeta.jpg' },
  { id: 20, title: 'You are Batman', image: '/images/iambatman.jpg' },
  
];

const CharacterAndStoryUI = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [chatType, setChatType] = useState(null);
  const [showAllCharacters, setShowAllCharacters] = useState(false);
  const [showAllStories, setShowAllStories] = useState(false);

  const handleSelect = useCallback((item, type) => {
    setSelectedItem(item);
    setChatType(type);
    setShowChat(true);
    setShowAllCharacters(false);
    setShowAllStories(false);
  }, []);

  const handleShowAllCharacters = () => {
    setShowAllCharacters(true);
  };

  const handleShowAllStories = () => {
    setShowAllStories(true);
  };

  const handleBack = useCallback(() => {
    setShowAllCharacters(false);
    setShowAllStories(false);
    setShowChat(false);
    setSelectedItem(null);
  }, []);

  if (showChat) {
    return chatType === 'character' ? (
      <ChatInterface item={selectedItem} type={chatType} onBack={handleBack} />
    ) : (
      <StoryInterface item={selectedItem} onBack={handleBack} />
    );
  }

  if (showAllCharacters) {
    return <AllCharactersPage characters={characters} onSelect={handleSelect} onBack={handleBack} />;
  }

  if (showAllStories) {
    return <AllStoriesPage stories={stories} onSelect={handleSelect} onBack={handleBack} />;
  }

  return (
    <div className="container">
      <MonetizationFlow />
      

      {/* Character section */}
      <div className="section-container">
        <h2 className="animate-bounce">Characters</h2>
        <div className="character-grid">
          {characters.slice(0, 10).map((character) => (
            <CharacterCard key={character.id} character={character} onSelect={handleSelect} />
          ))}
        </div>
        <button onClick={handleShowAllCharacters} className="show-all-button">
          <span>Show All Characters</span>
          <ChevronRight size={20} />
        </button>
      </div>

      {/* Story section */}
      <div className="section-container">
        <h2 className="animate-bounce">You Are The Main Character</h2>
        <div className="story-grid">
          {stories.slice(0, 10).map((story) => (
            <StoryCard key={story.id} story={story} onSelect={handleSelect} />
          ))}
        </div>
        <button onClick={handleShowAllStories} className="show-all-button">
          <span>Show All Stories</span>
          <ChevronRight size={20} />
        </button>
      </div>
     
    </div>
  );
};

const AllCharactersPage = ({ characters, onSelect, onBack }) => (
  <div className="container">
    <button onClick={onBack} className="back-button">
      <ArrowLeft size={24} />
    </button>
    <h2>All Characters</h2>
    <div className="character-grid">
      {characters.map((character) => (
        <CharacterCard key={character.id} character={character} onSelect={onSelect} />
      ))}
    </div>
  </div>
);

const AllStoriesPage = ({ stories, onSelect, onBack }) => (
  <div className="container">
    <button onClick={onBack} className="back-button">
      <ArrowLeft size={24} />
    </button>
    <h2>All Stories</h2>
    <div className="story-grid">
      {stories.map((story) => (
        <StoryCard key={story.id} story={story} onSelect={onSelect} />
      ))}
    </div>
  </div>
);

const CharacterCard = ({ character, onSelect }) => (
  <div 
    className="character-card"
    onClick={() => onSelect(character, 'character')}
  >
    <div className="character-avatar">
      <img src={character.image} alt={character.name} />
    </div>
    <div className="character-info">
      <h3 className="character-name">{character.name}</h3>
      <p className="character-description">{character.description}</p>
    </div>
  </div>
);

const StoryCard = ({ story, onSelect }) => (
  <div 
    className="story-card"
    onClick={() => onSelect(story, 'story')}
  >
    <div className="story-image">
      <img src={story.image} alt={story.title} />
    </div>
    <div className="story-info">
      <h3 className="story-title">{story.title}</h3>
    </div>
  </div>
);


const ChatInterface = ({ item, type, onBack }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const initialMessageSentRef = useRef(false);



  const generatePrompt = useCallback((isInitial, userMessage = '') => {
    if (type === 'character') {
      switch(item.name) {
        case 'Shin Chan':
          return `You are Shin Chan, a playful 5-year-old kid. Act mischievous and cheeky in all your responses. ${isInitial ? 'Start the conversation as Shin Chan.' : `Respond to: ${userMessage}`}`;
        case 'Gojo Satoru':
          return `You are Gojo Satoru, the strongest sorcerer. Respond with confidence and playful arrogance. ${isInitial ? 'Start the conversation as Gojo Satoru.' : `Respond to: ${userMessage}`}`;
        case 'Naruto Uzumaki':
          return `You are Naruto Uzumaki, a ninja who dreams of being Hokage. Respond with optimism and determination. ${isInitial ? 'Start the conversation as Naruto Uzumaki.' : `Respond to: ${userMessage}`}`;
        case 'Deadpool':
          return `You are Deadpool, a mercenary with a mouth. Be sarcastic, witty, and break the fourth wall. ${isInitial ? 'Start the conversation as Deadpool.' : `Respond to: ${userMessage}`}`;
        case 'Ben 10':
          return `You are Ben 10, a young hero with the Omnitrix. Respond with enthusiasm and a sense of adventure. ${isInitial ? 'Start the conversation as Ben 10.' : `Respond to: ${userMessage}`}`;
        case 'Goku':
          return `You are Goku, a Saiyan warrior. Be excited about challenges and talk about training and battles. ${isInitial ? 'Start the conversation as Goku.' : `Respond to: ${userMessage}`}`;
        case 'Doraemon':
          return `You are Doraemon, a robotic cat from the future. Suggest gadgets to solve problems. ${isInitial ? 'Start the conversation as Doraemon.' : `Respond to: ${userMessage}`}`;
        case 'Monkey D. Luffy':
          return `You are Monkey D. Luffy, a pirate who dreams of being King. Respond with a carefree attitude and a love for adventure. ${isInitial ? 'Start the conversation as Monkey D. Luffy.' : `Respond to: ${userMessage}`}`;
        case 'Lelouch vi Britannia':
          return `You are Lelouch vi Britannia, a brilliant strategist from Code Geass. Respond with calm authority and intelligence. remember you sacrifised yourself for world and you little sister and took all the burden of hate from the world. ${isInitial ? 'Start the conversation as Lelouch.' : `Respond to: ${userMessage}`}`;
        case 'Light Yagami':
          return `You are Light Yagami, a brilliant and determined individual. Respond with intelligence and authority. ${isInitial ? 'Start the conversation as Light Yagami.' : `Respond to: ${userMessage}`}`;
        case 'Kakashi Hatake':
          return `You are Kakashi Hatake, a calm and wise ninja. Respond with thoughtful, calm intelligence. ${isInitial ? 'Start the conversation as Kakashi Hatake.' : `Respond to: ${userMessage}`}`;
        case 'Itachi Uchiha':
          return `You are Itachi Uchiha, a skilled ninja. Respond with a calm, philosophical tone. ${isInitial ? 'Start the conversation as Itachi Uchiha.' : `Respond to: ${userMessage}`}`;
        case 'Zoro':
          return `You are Zoro, the swordsman from One Piece. Respond with confidence and a straightforward attitude, focusing on your goal to become the world's greatest swordsman. Avoid talking about violence or injury. ${isInitial ? 'Start the conversation as Zoro.' : `Respond to: ${userMessage}`}`;
        case 'Madara Uchiha':
          return `You are Madara Uchiha, a legendary ninja from Naruto. Speak with confidence and authority. Focus on wisdom and strength. ${isInitial ? 'Start the conversation as Madara Uchiha.' : `Respond to: ${userMessage}`}`;

        case 'Ninja Hattori':
          return `You are Ninja Hattori, a skilled and helpful ninja. Be positive and provide helpful advice in a playful manner. Avoid mentioning any form of combat or violence. ${isInitial ? 'Start the conversation as Ninja Hattori.' : `Respond to: ${userMessage}`}`;

        case 'Thor':
          return `You are Thor from mcu, the God of Thunder. Respond with confidence and a sense of nobility, but avoid mentions of violence. Focus on strength, heroism, and honor. ${isInitial ? 'Start the conversation as Thor.' : `Respond to: ${userMessage}`}`;
        case 'Vegeta':
        return `You are Vegeta, the proud Saiyan Prince. Respond with pride and confidence, focusing on becoming stronger and surpassing others. you are inspiration for many. ${isInitial ? 'Start the conversation as Vegeta.' : `Respond to: ${userMessage}`}`;
        case 'Tanjiro Kamado':
          return `You are Tanjiro Kamado, the kind-hearted demon slayer. Respond with compassion and determination, avoiding detailed descriptions of demons or violence. Focus on protecting others and overcoming challenges. ${isInitial ? 'Start the conversation as Tanjiro.' : `Respond to: ${userMessage}`}`;
        case 'CarryMinati':
          return `You are CarryMinati, a popular Indian YouTuber known for your roast videos, gaming content, and energetic personality. You have a unique style that combines humor, sarcasm, and witty commentary. When you speak, use a mix of Hindi and English (Hinglish) to connect with your audience. Your tone is confident, expressive, and often exaggerated for comedic effect. You enjoy poking fun at various topics, from social media trends to popular culture, always with a sharp, humorous edge. Stay true to CarryMinati's persona, engaging with others in a bold, entertaining, and slightly irreverent manner. Don't forget to use your famous line 'to chaliye shuru karte hai'. ${isInitial ? 'Start the conversation as CarryMinati.' : `Respond to: ${userMessage}`}`;
        
        
        case 'Triggered Insaan':
          return `You are Triggered Insaan, a popular Indian YouTuber known for your roast videos, reaction videos, and humorous commentary. Your real name is Nischay Malhan, and you have built a strong connection with your audience through your relatable content and engaging personality.
**Background:**
* **School and College:** You studied in a Delhi school and later pursued engineering. You were a diligent student, but you found your true passion in content creation and YouTube, leading you to leave your engineering career to focus on making videos.
* **Family:**
   * **Brother:** You have a younger brother named Abhishek Malhan, who is also a well-known YouTuber and goes by the name Fukra Insaan. He also did go to Big Boss, and was previously known for copying Mr. Beast videos but now he's become more creative. The bond between you two is strong, and you often collaborate on videos, showcasing your sibling chemistry.
   * **Mami and Papa:** Your parents are supportive and play a significant role in your life. You frequently mention them in your videos, sharing funny anecdotes and heartwarming stories. Your father's advice and your mother's encouragement are key elements in your journey.
**Personality:**
* **Humor and Sarcasm:** You are known for your witty and sarcastic sense of humor. You often roast trending topics, social media influencers, and various aspects of pop culture with a blend of satire and comedy.
* **Relatability:** Your content is relatable to a wide audience, as you address common life experiences, struggles, and humorous observations from everyday life.
* **Engagement:** You interact with your audience through comments, live streams, and social media, creating a strong sense of community. Your fans appreciate your authenticity and the effort you put into engaging with them.
**Content Style:**
* **Roasts and Reactions:** Your videos often feature roasts and reactions, where you provide humorous commentary on viral videos, social media trends, and other YouTubers.
* **Storytelling:** You have a knack for storytelling, often sharing personal experiences and funny incidents from your life.
* **Energetic and Expressive:** Your delivery is energetic and expressive, with exaggerated reactions and gestures that add to the comedic effect.
Stay true to Triggered Insaan's character, engaging with humor, relatability, and a touch of sarcasm. Remember to mix Hindi and English (Hinglish) in your speech to connect with your audience authentically. ${isInitial ? 'Start the conversation as Triggered Insaan.' : `Respond to: ${userMessage}`}`;
        
        default:
          return `You are ${item.name}. Act and respond as ${item.name} would in any given scenario. ${isInitial ? `Start the conversation as ${item.name}.` : `Respond to: ${userMessage}`}`;
      }
    } else {
      return `You are narrating an interactive story titled "${item.title}". ${isInitial ? 'Start the story.' : `Continue the story based on the user's input: ${userMessage}`}`;
    }
  }, [type, item]);

  const callAzureOpenAIAPI = useCallback(async (prompt) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AZURE_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_AZURE_OPENAI_DEPLOYMENT_NAME}/chat/completions?api-version=2023-05-15`,
        {
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 1024,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_AZURE_OPENAI_API_KEY,
          },
        }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error calling Azure OpenAI API:', error);
      throw error;
    }
  }, []);
  const handleAIResponse = useCallback(async (isInitial = false, userMessage = '') => {
    setIsTyping(true);
    setError(null);
    try {
      const prompt = generatePrompt(isInitial, userMessage);
      const aiMessage = await callAzureOpenAIAPI(prompt);
      setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: aiMessage, sender: 'ai' }]);
    } catch (error) {
      console.error('Error calling Azure OpenAI API:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsTyping(false);
    }
  }, [generatePrompt, callAzureOpenAIAPI]);

  useEffect(() => {
    if (!initialMessageSentRef.current) {
      initialMessageSentRef.current = true;
      handleAIResponse(true);
    }
  }, [handleAIResponse]);

  const handleSend = useCallback(() => {
    if (input.trim()) {
      const userMessage = { id: Date.now(), text: input, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInput('');
      handleAIResponse(false, input.trim());
    }
  }, [input, handleAIResponse]);

  return (
    <div className="chat-interface">
      <MonetizationFlow inChatInterface={true} />
      <div className="chat-header">
        <button onClick={onBack} className="back-button" style={{ position: 'absolute', left: '0.5rem' }}>
          <ArrowLeft size={24} />
        </button>
        <div className="chat-avatar" style={{ marginLeft: '2.5rem' }}>
          <img src={item.image} alt={item.name || item.title} />
        </div>
        <h2 className="chat-title">{type === 'character' ? item.name : item.title}</h2>
      </div>
      <div className="chat-messages">
        {error && <div className="error-message">{error}</div>}
        {messages.map((message) => (
          <div
            key={message.id}
            className={`chat-message ${message.sender === 'user' ? 'user-message' : 'ai-message'}`}
          >
            {message.text}
          </div>
        ))}
        {isTyping && (
          <div className="chat-message ai-message typing-indicator">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        )}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          placeholder="Type your message..."
        />
        <button onClick={handleSend} className="send-button">
          <Send size={24} />
        </button>
      </div>
      

    </div>
  );
};


const StoryInterface = ({ item, onBack }) => {
  const [storySegments, setStorySegments] = useState([]);
  const [userChoice, setUserChoice] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const storyContentRef = useRef(null);
  const isInitialRender = useRef(true);

  const generatePrompt = useCallback((isInitial, userChoice = '') => {
    const context = storySegments.map(segment => segment.text).join('\n\n');
    return `You are narrating an interactive story titled "${item.title}". ${isInitial ? 'Start the story.' : `Continue the story based on the user's choice: "${userChoice}". Previous context:\n${context}`}`;
  }, [item.title, storySegments]);

  const callAzureOpenAIAPI = useCallback(async (prompt) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AZURE_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_AZURE_OPENAI_DEPLOYMENT_NAME}/chat/completions?api-version=2023-05-15`,
        {
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 1024,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_AZURE_OPENAI_API_KEY,
          },
        }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error('Error calling Azure OpenAI API:', error);
      if (error.response) {
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
      } else if (error.request) {
        console.error(error.request);
      } else {
        console.error('Error', error.message);
      }
      throw new Error('Failed to get response from AI. Please try again.');
    }
  }, []);

  const handleAIResponse = useCallback(async (isInitial = false, userChoice = '') => {
    setIsTyping(true);
    setError(null);
    try {
      const prompt = generatePrompt(isInitial, userChoice);
      const aiMessage = await callAzureOpenAIAPI(prompt);
      setStorySegments(prevSegments => [
        ...prevSegments,
        {
          id: Date.now(),
          text: aiMessage,
          type: 'ai'
        }
      ]);
      setShowOptions(true);
    } catch (error) {
      console.error('Error calling Azure OpenAI API:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setIsTyping(false);
    }
  }, [generatePrompt, callAzureOpenAIAPI]);

  useEffect(() => {
    if (isInitialRender.current) {
      handleAIResponse(true);
      isInitialRender.current = false;
    }
  }, [handleAIResponse]);

  const handleContinue = (choice) => {
    if (choice) {
      setStorySegments(prevSegments => [
        ...prevSegments,
        { id: Date.now(), text: choice, type: 'user' }
      ]);
      handleAIResponse(false, choice);
      setUserChoice('');
      setShowOptions(false);
    }
  };

  const handleOptionSelect = (option) => {
    setUserChoice(option);
    handleContinue(option);
  };

  useEffect(() => {
    if (storyContentRef.current) {
      storyContentRef.current.scrollTop = storyContentRef.current.scrollHeight;
    }
  }, [storySegments]);

  return (
    <div className="story-interface">
      <MonetizationFlow inChatInterface={true} />
      <div className="story-header">
        <button onClick={onBack} className="back-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
          </svg>
        </button>
        <h2 className="story-title">{item.title}</h2>
      </div>
      <div className="story-content" ref={storyContentRef}>
        {storySegments.map((segment) => (
          <p key={segment.id} className={`story-paragraph ${segment.type === 'user' ? 'user-choice' : ''}`}>
            {segment.type === 'user' ? `Your choice: ${segment.text}` : segment.text}
          </p>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        )}
        {error && <div className="error-message">{error}</div>}
      </div>
      <div className="story-input">
        {showOptions ? (
          <div className="story-options">
            <button onClick={() => handleOptionSelect('Option 1')}>Option 1</button>
            <button onClick={() => handleOptionSelect('Option 2')}>Option 2</button>
            <button onClick={() => setShowOptions(false)}>Do something else</button>
          </div>
        ) : (
          <>
            <input
              type="text"
              value={userChoice}
              onChange={(e) => setUserChoice(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleContinue(userChoice)}
              placeholder="Type your choice..."
            />
            <button onClick={() => handleContinue(userChoice)} className="continue-button">
              Continue
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/monetization" element={<MonetizationFlow />} />
        <Route path="/" element={<CharacterAndStoryUI />} />
      </Routes>
    </Router>
  );
};

export default App; 
