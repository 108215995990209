import React, { useState, useEffect } from 'react';

import QRCode from 'qrcode.react';
import emailjs from 'emailjs-com';
import { X } from 'lucide-react';
import './Monetization.css';
import { Link } from 'react-router-dom';

const CloseButton = ({ onClick }) => (
  <button onClick={onClick} className="close-button">
    <X size={24} />
  </button>
);

const AdPoster = ({ onCreateClick, onClose }) => {
  return (
    <div className="ad-poster">
      <CloseButton onClick={onClose} />
      <h2>Create Your Own Character or Story</h2>
      <p>For just <span className="highlight">₹49</span>, your creation will appear on the home page for <span className="highlight">everyone to see!</span></p>
      <button onClick={onCreateClick} className="create-button">Create Now</button>
      <p className="limited-time">Limited Time Offer!</p>
    </div>
  );
};

const CreationForm = ({ onSubmit, onClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    try {
      emailjs.init("wi9BAhm_XEFDm74J4");

      const templateParams = {
        from_name: "Your Website",
        subject: "New Character or Story Submission",
        message: `Name: ${name}\nDescription: ${description}`
      };

      const response = await emailjs.send("service_cg8kb9j", "template_fmc2phj", templateParams);
      
      console.log('Email sent successfully', response);
      onSubmit({ name, description });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while submitting your creation. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="creation-form-container">
      <CloseButton onClick={onClose} />
      <form onSubmit={handleSubmit} className="creation-form">
        <h2>Fill details about your character or story you want to create</h2>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name of your Character or Story"
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description of your Character or Story"
          required
        />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Create'}
        </button>
      </form>
    </div>
  );
};

const PaymentPage = ({ onPaymentComplete, onClose }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowButton(true), 9000); // Set to 5 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="payment-page">
      <CloseButton onClick={onClose} />
      <h2>Complete Your Payment</h2>
      <p>Pay 49 rupees to this QR code for payment</p>
      <QRCode value="vivekkumardhigarla24@oksbi" /> 
      <a href="/images/qr_code.png" download="/images/qr_code.png">Download QR Code</a>
      {showButton && (
        <button onClick={onPaymentComplete} className="payment-complete-button">
          I've Completed the Payment
        </button>
      )}
    </div>
  );
};

const ConfirmationPopup = ({ onClose }) => {
  return (
    <div className="confirmation-popup">
      <h2>Payment Confirmation</h2>
      <p>We will confirm the payment and your character or story will appear on the home page within one day.</p>
      <button onClick={onClose} className="ok-button">OK</button>
    </div>
  );
};

const MonetizationFlow = ({ inChatInterface = false }) => {
  const [showCreationForm, setShowCreationForm] = useState(false);
  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [creationType, setCreationType] = useState('');

  const handleFormSubmit = async (formData) => {
    try {
      emailjs.init("wi9BAhm_XEFDm74J4");

      const templateParams = {
        to_email: "vivekkumardhigarla24@gmail.com",
        from_name: "Your Website",
        subject: `New ${formData.type} Submission`,
        message: `Name: ${formData.name}\nDescription: ${formData.description}\nType: ${formData.type}`
      };

      await emailjs.send("service_cg8kb9j", "template_fmc2phj", templateParams);

      console.log('Email sent successfully');
      setShowCreationForm(false);
      setShowPaymentPage(true);
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while submitting your creation. Please try again.');
    }
  };

  const handlePaymentComplete = () => {
    setShowPaymentPage(false);
    setShowConfirmation(true);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
    window.location.href = '/';
  };

  const handleClose = () => {
    setShowCreationForm(false);
    setShowPaymentPage(false);
  };

  return (
    <>
      {showCreationForm && (
        <div className={inChatInterface ? "chat-ad-overlay" : ""}>
          <CreationForm
            type={creationType}
            onSubmit={handleFormSubmit}
            onClose={handleClose}
          />
        </div>
      )}
      {showPaymentPage && (
        <div className={inChatInterface ? "chat-ad-overlay" : ""}>
          <PaymentPage onPaymentComplete={handlePaymentComplete} onClose={handleClose} />
        </div>
      )}
      {showConfirmation && (
        <div className={inChatInterface ? "chat-ad-overlay" : ""}>
          <ConfirmationPopup onClose={handleConfirmationClose} />
        </div>
      )}
      <div className="sticky-terms">
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </div>
    </>
  );
};

export default MonetizationFlow;

