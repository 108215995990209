import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h1>Terms and Conditions</h1>

      <h2>Introduction</h2>
      <p>
        Welcome to Saga Walk! These Terms and Conditions outline the rules and regulations for the use of our website and services. By accessing or using our website, you agree to comply with these terms. If you do not agree with any part of these terms, you must not use our website.
      </p>

      <h2>Definitions</h2>
      <p>
        - <strong>Website</strong>: Refers to the Saga Walk website and all its content.<br />
        - <strong>User</strong>: Refers to any individual who accesses or uses the website.<br />
        - <strong>Services</strong>: Refers to the features and functionalities provided on the website.
      </p>

      <h2>Acceptance of Terms</h2>
      <p>
        By using our website, you confirm that you are at least 13 years old and have the legal capacity to enter into these Terms and Conditions. If you are using the website on behalf of an organization, you represent that you have the authority to bind that organization to these terms.
      </p>

      <h2>Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on the website. Your continued use of the website after any changes constitutes your acceptance of the new terms.
      </p>

      <h2>User Responsibilities</h2>
      <p>
        As a user of our website, you agree to:
        <ul>
          <li>Use the website in compliance with all applicable laws and regulations.</li>
          <li>Not engage in any activity that may harm the website or its users.</li>
          <li>Not use the website for any unlawful or prohibited purpose.</li>
        </ul>
      </p>

      <h2>Intellectual Property</h2>
      <p>
        All content on the website, including text, graphics, logos, and images, is the property of Saga Walk or its licensors and is protected by copyright and intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our express written permission.
      </p>

      <h2>Disclaimer of Warranties</h2>
      <p>
        The website and its services are provided on an "as is" and "as available" basis. We do not warrant that the website will be uninterrupted, secure, or free of errors. We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, Saga Walk shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the website or services. This includes, but is not limited to, damages for loss of profits, data, or other intangible losses.
      </p>

      <h2>Governing Law</h2>
      <p>
        These Terms and Conditions shall be governed by and construed in accordance with the laws of your jurisdiction. Any disputes arising from these terms shall be resolved in the courts of your jurisdiction.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions about these Terms and Conditions, please contact us on vivekkumardhigarla24@gmail.com
      </p>

      <p><strong>Last Updated:</strong> [08-11-2024]</p>
    </div>
  );
};

export default TermsAndConditions; 